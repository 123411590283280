
<template>
      <b-modal
      id="addFoServicesModal"
      ref="modal"
      title="Add Fo Service"
      @show="resetModal"
      @hidden="resetModal"
      hide-footer
    >
      <form ref="form" @submit.prevent="fo_add_service">
        <b-alert show variant="danger" v-if="error" class="text-center">{{msg}}</b-alert>
        <b-form-group
          label="Title"
          label-for="name-input"
          invalid-feedback="Title is required"
        >
          <b-form-input
            id="name-input"
            v-model="fo_service_title"
            :placeholder="modal_placeholder"
            :class="{ 'is-invalid': isValid && $v.fo_service_title.$error }"
          ></b-form-input>
        </b-form-group>
      <b-button variant="primary" v-on:click="fo_add_service">Submit</b-button>
      </form>
     </b-modal>
</template>

<script>
import { foServiceMethods } from "@/state/helpers";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      msg : "",
      error:false,
      isValid:false,
      fo_service_title : '',
    };
  },
  props: {
    modal_placeholder: {
      type: String,
    } 
  },
  validations: {
    fo_service_title: {
      required
    },
  },
  methods: {
    ...foServiceMethods,
    resetModal(){
      this.fo_service_title='';
      this.msg='';
      this.error=false;
      this.isValid = false;
    },
    fo_add_service() {
      this.msg='';
      this.isValid = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      this.addFoService({service_id:this.$route.params.service_id,title:this.fo_service_title}).then(res => {
        this.$refs['modal'].hide();
        if (res.status == 200) {
        this.msg = res.message ? res.message : "Fo service added successfully"
        this.$swal({
            icon : 'success',
            toast : true,
            text: this.msg,
          showCloseButton: false,
            showConfirmButton: false,
            timer: 3000
        }).then(
        this.getAllFoServices({id:this.$route.params.service_id}),
         );
        }
      }).catch(error => {
        this.error = true;
        if (error.data.status == 422) {
          this.msg = error.data.errors.title[0] ? error.data.errors.title[0] : "Something went wrong!!!"
        }
        else{
        this.$swal({
            icon : 'error',
            toast : true,
            text: "Failed to save! Try again",
          showCloseButton: false,
            showConfirmButton: false,
            timer: 3000
        });
        }
      });
    },
  },
  mounted() {
  }
};
</script>
